<template>
    <div>

    </div>
</template>

<script>
// @ is an alias to /src
export default {
  data () {
    return {
      valid: true,
      showErr: false,
      errTexts: [],
      requiredRules: [
        v => !!v || 'שדה חובה'
      ],
      requiredRulesEmpty: [],
      usernameRules: [
        v => !!v || 'שדה חובה',
        v => /.+@.+\..+/.test(v) || 'יש להקליד דואר אלקטרוני תקין'
      ]
    }
  },
  created () {
    window.Store.commit('Loader', false)
    this.showErr = false
    this.errTexts = []
  },
  methods: {
  }
}
</script>
