<template>
  <div class="home black--text">
    <v-carousel
    cycle
    hide-delimiter-background
    show-arrows-on-hover
    height="450"
    >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
   <v-row class="pt-5">
    <v-col cols="12" xs="9" sm="9">
חברת אנקום גרופ בע"מ הוקמה בשנת 1996. חברת אנקום גרופ בע"מ הנה חברה יזמית המנהלת מגוון גדול של נכסים איכותיים למגורים ברחבי ישראל – בערי המרכז ובפריפריה.<br>
לאורך שנים רבות של עשייה והצלחות, חברת אנקום גרופ בע"מ , יזמה בנתה ומנהלת  עבור אינספור משפחות ישראליות, מאות יחידות דיור בעשרות פרויקטים מובילים ובסטנדרט חסר פשרות.<br>
חברת אנקום גרופ בע"מ, מאפשרת ללקוחותיה ליהנות מביטחון ושקט נפשי. כל פרויקט של החברה, מתאפיין בתכנון קפדני ובביצוע מדוקדק, המתעלים כל פעם לשלב חדש של איכות. כל זאת, תוך שימת הלקוח וצרכיו במרכז, והקפדה על שירות אישי ומקצועי.<br>
אנקום גרופ בע"מ, עוסקת גם בניהול שוטף ואחזקת נכסים. החברה מתמחה בניהול דירות, משרדים וחנויות. השירות מקיף את הטיפול השוטף בכל הקשור להשכרת נכס - החל מפרסום, איתור שוכר מתאים, סינון הפניות, החתמה על חוזה שכירות, גביית כספים, וכן ליווי בכל שלבי הרישום והעברה בעירייה ורשויות השונות כגון, חברת החשמל, מפעל המים וכדומה.<br>
אנקום גרופ בע"מ מטפלת בכל האחזקה השוטפת של הנכס. למעשה, השירות מאפשר לשוכר הנכס ליהנות ללא השקעת זמן, משאבים ותסכולים.<br>
אנקום גרופ בע"מ הנה חברה שמטרתה לשרת את כלל לקוחותיה באמצעות הון אנושי ייחודי, היוצר תשתית איתנה של הגינות, אמינות ונאמנות.
    </v-col>
    <v-col cols="12" xs="3" sm="3">
    </v-col>
   </v-row>
  </div>
</template>

<script>
import { bus } from './../main'
import { mask } from 'vue-the-mask'
// @ is an alias to /src
export default {
  directives: {
    mask
  },
  data () {
    return {
      BrancheSelected: '',
      items: [
        {
          src: require('@/assets/images/home.jpg')
        },
        {
          src: require('@/assets/images/home2.jpg')
        },
        {
          src: require('@/assets/images/home3.jpg')
        },
        {
          src: require('@/assets/images/home4.jpg')
        }
      ],
      Branches: [
        { id: 1, title: 'גבעול מרחוב הירקון 288, תל אביב', city: 'ת"א', address: 'הירקון 288', email: 'approved@givol-pharmacy.co.il', 'signName': 'יארא סלאמה', 'signGender': 'רוקחת אחראית', 'sign': 'yara.png' },
        { id: 2, title: 'גבעול מרחוב יפו 216, ירושלים', city: 'ירושלים', address: 'יפו 216', email: 'jerusalem@givol-pharmacy.co.il', 'signName': 'רווית אורן', 'signGender': 'רוקחת אחראית', 'sign': 'ravit.png' },
        { id: 3, title: 'גבעול מרחוב גיא אוני 12, אשדוד', city: 'אשדוד', address: 'גיא אוני 12', email: 'alaa.agamy@givol-pharmacy.co.il', 'signName': 'אבו עגמי עלא', 'signGender': 'רוקח אחראי', 'sign': 'abu.png' },
        { id: 4, title: 'גבעול מרחוב רבי שמעון בר יוחאי 6, דימונה', city: 'דימונה', address: 'רבי שמעון בר יוחאי 6', email: 'dimona@givol-pharmacy.co.il', 'signName': 'איברהים אל טורי', 'signGender': 'רוקח אחראי', 'sign': 'Ivrahim.png' },
        { id: 5, title: 'גבעול מרחוב אוסקר שינדלר 7, חיפה', city: 'חיפה', address: 'אוסקר שינדלר 7', email: 'haifa@givol-pharmacy.co.il', 'signName': 'אשרף חלאילה', 'signGender': 'רוקח אחראי', 'sign': '' },
        { id: 6, title: 'גבעול מרחוב גאולה 6, רעננה', city: 'רעננה', address: 'גאולה 6', email: 'doroncann@pharmacare.co.il', 'signName': 'נטליה רונן', 'signGender': 'רוקחת אחראית', 'sign': 'natalia.png' },
        { id: 7, title: 'גבעול מרחוב מונטיפיורי 1, באר שבע', city: 'באר שבע', address: 'מונטיפיורי 1', email: 'approved@givol-pharmacy.co.il', 'signName': 'רפאל ריינר', 'signGender': 'רוקח אחראי', 'sign': '' },
        { id: 8, title: 'גבעול מרחוב חבצלת השרון 36, הרצליה', city: 'הרצליה', address: 'חבצלת השרון 36', email: 'Power@cannolam.com', 'signName': 'אמגד עזאם', 'signGender': 'רוקח אחראי', 'sign': 'amjad.png' },
        { id: 9, title: 'גבעול מרחוב תל חי 63, אשדוד', city: 'אשדוד', address: 'תל חי 63', email: 'Power@cannolam.com', 'signName': 'תומר דביר', 'signGender': 'רוקח אחראי', 'sign': 'tomer.png' },
        { id: 10, title: 'גבעול מרחוב הכרמל 2, יקנעם עילית', city: 'יקנעם עילית', address: 'הכרמל 2', email: 'approved@givol-pharmacy.co.il', 'signName': 'שרה אבו ניל', 'signGender': 'רוקחת אחראית', 'sign': 'sara.png' }
      ],
      Dob: window.moment().format('DD/MM/YYYY'),
      Times: window.moment().format('H:mm'),
      deliveryName: null,
      deliverySelected: '',
      delivery: [
        { id: 1, title: 'תקין (לפי נוהל משרד הבריאות בין 15-25 מעלות צלזיוס)' },
        { id: 2, title: 'לא תקין' }
      ],
      TempsSelected: '',
      Temps: [
        { id: 1, title: '15 מעלות צלזיוס' },
        { id: 2, title: '16 מעלות צלזיוס' },
        { id: 3, title: '17 מעלות צלזיוס' },
        { id: 4, title: '18 מעלות צלזיוס' },
        { id: 5, title: '19 מעלות צלזיוס' },
        { id: 6, title: '20 מעלות צלזיוס' },
        { id: 7, title: '21 מעלות צלזיוס' },
        { id: 8, title: '22 מעלות צלזיוס' },
        { id: 9, title: '23 מעלות צלזיוס' },
        { id: 10, title: '24 מעלות צלזיוס' },
        { id: 11, title: '25 מעלות צלזיוס' }
      ],
      tempExtra: null,
      firstName: null,
      lastName: null,
      clientId: null,
      Address: null,
      Email: null,
      clientMobile: null,
      gender: null,
      marketing: false,
      roles: false,
      genders: ['זכר', 'נקבה'],
      date: null,
      dateFormatted: null,
      menu: false,
      valid: true,
      showErr: false,
      errTexts: [],
      breadcrumbs: [
        {
          text: 'לוח בקרה',
          disabled: false,
          to: { path: '/' }
        }
      ],
      requiredRules: [
        v => !!v || 'שדה חובה'
      ],
      requiredRulesEmpty: [],
      usernameRules: [
        v => !!v || 'שדה חובה',
        v => /.+@.+\..+/.test(v) || 'יש להקליד דואר אלקטרוני תקין'
      ]
    }
  },
  created () {
    window.Store.commit('Loader', false)
    this.showErr = false
    this.errTexts = []
    // this.$refs.signaturePad.resizeCanvas()
    this.$nextTick(() => {
      this.Dob = window.moment().format('DD/MM/YYYY')
      this.date = this.parseDate(this.Dob)
    })
  },
  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      let vm = this
      setTimeout(function () {
        vm.Dob = window.moment().format('DD/MM/YYYY')
        vm.date = vm.parseDate(vm.Dob)
        vm.Times = window.moment().format('H:mm')
      }, 2000)
    },
    undo () {
      this.$refs.signaturePad.clearSignature()
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    Sendform () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml')
      this.showErr = false
      this.Dob = window.moment().format('DD/MM/YYYY')
      this.Times = window.moment().format('H:mm')
      this.errTexts = []
      let dataQ = {
        firstName: this.firstName,
        lastName: this.lastName,
        clientId: this.clientId,
        pharm: this.BrancheSelected,
        signatureJSON: data,
        deliveryName: this.deliveryName,
        tempExtra: this.tempExtra,
        deliverySelected: this.deliverySelected.title,
        deliverySelectedId: this.deliverySelected.id,
        tempsSelected: this.TempsSelected.title
      }
      let vm = this
      if (!this.$refs.contactus.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.error--text:first-of-type > div > div:nth-child(1) > div > input')
          const elRadio = this.$el.querySelector('.error--text:first-of-type > div > div:nth-child(1) > div > div > div > input')
          const el2 = this.$el.querySelector('.error--text:first-of-type')
          // el.focus()
          if ((!!el && el.type === 'text') || (!!el && el.type === 'email') || (!!el && el.type === 'tel')) {
            el.focus()
          } else if (!!elRadio && elRadio.type === 'radio') {
            elRadio.focus()
          } else {
            el.focus()
          }
          this.$vuetify.goTo(el2)
        })
      }
      if (this.$refs.contactus.validate()) {
        if (!isEmpty) {
          window.Store.commit('Loader', true)
          window.axios.post(window.API.registerForm, dataQ)
            .then(response => {
              window.Store.commit('Loader', false)
              bus.$emit('Open_snackbar', 'success', 'נשלח בהצלחה!', '1')
              vm.$refs.contactus.reset()
              vm.$refs.signaturePad.clearSignature()
              vm.resetData()
            })
            .catch(error => {
              window.Store.commit('Loader', false)
              vm.showErr = true
              let errorMessage = error.response.data
              vm.errTexts = errorMessage.err
              bus.$emit('Open_snackbar', 'error', errorMessage.err)
            })
        } else {
          bus.$emit('Open_snackbar', 'error', 'התגלתה שגיאה, יש לחתום בתחתית הטופס', '1')
        }
      } else {
        bus.$emit('Open_snackbar', 'error', 'התגלתה שגיאה, אנא בדוק את הטופס', '1')
      }
    },
    onFocus () {
      this.$refs.autocomplete.isMenuActive = true
    }
  },
  mounted () {
    // let autocompleteInput = this.$refs.autocomplete.$refs.input
    // autocompleteInput.addEventListener('focus', this.onFocus, true)
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    }
  }
}
</script>

<style>
.theme--light.v-messages {
  color: #000000!important;
}
.theme--light.v-label {
  color: #000000!important;
}
.style1 {
  color: #004337!important;
}
.style2 {
  color: #004337!important;
}
</style>
