<template>
  <nav>
     <v-app-bar
     :clipped-right="$vuetify.breakpoint.lgAndUp"
      app
      dense
      flat
      light
      padless
      color="blue"
      id="home"
      class="text-center white--text"
    >
     <v-spacer></v-spacer>
    <v-toolbar-title>חברת אנקום גרופ בע"מ</v-toolbar-title>
      <template>
      <v-spacer></v-spacer>
      <v-btn to="/" text class="ml-1 mr-2 white--text" v-if="!isMobile">
      ראשי
      </v-btn>
      <v-btn @click="$vuetify.goTo('#contactform', options)" text class="mx-1 white--text" v-if="!isMobile">
      צור קשר
      </v-btn>
      <v-spacer></v-spacer>
     <v-hover>
   <a slot-scope="{ hover }"
  :style="`${hover? '': 'text-decoration:none;'}`" class="hvr-grow white--text" href="tel:972535420420">053-5420420 <v-icon small left color="white" v-if="!isMobile">mdi-phone</v-icon></a>
  </v-hover>
      <v-spacer></v-spacer>
      </template>
    </v-app-bar>
  </nav>
</template>

<script>

import { bus } from '../main'
// import Pusher from 'pusher-js' // import Pusher
// const audioGetMessage = new Audio(require('@/assets/sound/1.mp3'))
import * as easings from 'vuetify/es5/services/goto/easing-patterns'

export default {
  props: {
    source: String
  },
  name: 'Navbar',
  data () {
    return {
      fab: false,
      isMobile: false,
      drawer: false,
      type: 'element',
      number: 9999,
      selected: 'Button',
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
      easings: Object.keys(easings)
    }
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  created () {
    this.drawer = false
  },
  methods: {
    openRegisterDialog () {
      bus.$emit('Open_registerDialog')
    },
    onResize () {
      this.isMobile = window.innerWidth < 600
      if (!this.isMobile) {
        this.drawer = false
      }
    },
    element () {
      if (this.selected === 'Button') return this.$refs.addons
      else if (this.selected === 'Radio group') return this.$refs.radio
      else return null
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  watch: {
    $route (to, from) {
      Object.assign(this.$data, this.$options.data.call(this))
    }
  },
  computed: {
    activeFab () {
      switch (this.$route.name) {
        case 'clients': return { color: 'green', icon: 'add', display: true, BtnGroup: '1' }
        case 'home': return { color: 'green', icon: 'add', display: true, BtnGroup: '2' }
        case 'scheduler': return { color: 'green', icon: 'add', display: true, BtnGroup: '3' }
        case 'task': return { color: 'green', icon: 'add', display: true, BtnGroup: '10' }
        case 'pipeline': return { color: 'green', icon: 'add', display: false, BtnGroup: '4' }
        case 'items': return { color: 'green', icon: 'add', display: false, BtnGroup: '5' }
        case 'docs': return { color: 'green', icon: 'add', display: true, BtnGroup: '6' }
        case 'settings': return { color: 'green', icon: 'help', display: true, BtnGroup: '7' }
        case 'generalSettings': return { color: 'green', icon: 'help', display: false, BtnGroup: '8' }
        case 'three': return { color: 'green', icon: 'keyboard_arrow_up', display: true, BtnGroup: '9' }
        default: return {}
      }
    },
    target () {
      const value = this[this.type]
      if (!isNaN(value)) return Number(value)
      else return value
    },
    options () {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      }
    }
  }
}
</script>

<style scoped>
.activeStyle {
  color: #2196F3!important;
}
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 0px!important;
}
.btnnav {
}
</style>
