<template>
  <v-app id="app" class="background">
    <Navbar ref="NavBarSettings"/>
    <v-main>
      <v-container>
        <router-view />
        <div class="ma-10"></div>
      </v-container>
    </v-main>
     <v-footer padless>
       <div id="contactform"></div>
       <v-card-text class="white--text text-center">
<v-alert color="white--text ma-4" style="background-color:#016eb8;">
<v-form
  ref="contactus"
  v-model="valid"
  lazy-validation
>
<v-row class="mx-5">
<v-col cols="12">
<p class="pb-0 mb-0 text-h5 font-weight-bold">צור קשר</p>
<!-- <p class="text-h5">משהו לא ברור? שאלות? מחמאות? אנחנו כאן בשבילכם</p> -->
</v-col>
<v-col cols="12">
<v-row dense>
<v-col cols="12" xs="3" sm="3">
   <v-text-field
    v-model="fullName"
    label="שם מלא"
    solo
    :rules="requiredRules"
    required
  ></v-text-field>
</v-col>
<v-col cols="12" xs="3" sm="3">
     <v-text-field
     v-model="email"
    label="אימייל"
    solo
    :rules="usernameRules"
    required
  ></v-text-field>
</v-col>
<v-col cols="12" xs="3" sm="3">
     <v-text-field
    v-model="phoneNumber"
    label="טלפון"
    solo
    :rules="requiredRules"
    required
    type="tel"
    v-mask="'##########'"
  ></v-text-field>
</v-col>
<v-col cols="12" xs="3" sm="3">
<v-hover>
<v-btn
  slot-scope="{ hover }"
  :color="`${hover? 'primary': 'black'}`"
  class="white--text"
  dark
  tile
  block
  large
  @click="SendContactForm"
>
שלח
<v-icon right>arrow_back</v-icon>
</v-btn>
</v-hover>
</v-col>
</v-row>
</v-col>
</v-row>
</v-form>
<v-alert
    v-show="showErr"
    dense
    color="error"
    dark
    class="justify-center text-center white--text mx-2"
  >
    <ul class="errLogin">
      <span>
      <template v-for="errText in errTexts">
        <li
          v-for="errElement in errText"
          :key="errElement"
        >
          {{ errElement }}
        </li>
      </template>
      </span>
    </ul>
  </v-alert>
</v-alert>
</v-card-text>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>חברת אנקום גרופ בע"מ</strong>
    </v-col>
  </v-footer>
    <v-snackbar
      style="z-index:999999"
      v-model="snackbar"
      :timeout="snackbartimeout"
      :color="snackbarError"
    >
      <ul class="errLogin" v-if="ErrorArray">
        <template v-for="errText in snackbarText">
          <li
            v-for="errElement in errText"
            :key="errElement"
          >
            {{ errElement }}
          </li>
        </template>
      </ul>
      <ul class="errLogin" v-else>
          <li>
            {{ snackbarText }}
          </li>
      </ul>
      <template v-slot:action="{ attrs }">
      <v-btn
        color="danger"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        סגור
      </v-btn>
      </template>
    </v-snackbar>
    <Loading />
  </v-app>
</template>

<script>
import { bus } from './main'

import Navbar from './core/Navbar'
import Loading from './components/loading.vue'
import { mask } from 'vue-the-mask'

export default {
  components: {
    Navbar,
    Loading
  },
  directives: {
    mask
  },
  data () {
    return {
      isMobile: false,
      dialogLeft: false,
      snackbar: false,
      ErrorArray: true,
      snackbarText: null,
      snackbarError: null,
      snackbartimeout: 2000,
      dialogTerms: false,
      phoneNumber: null,
      email: null,
      fullName: null,
      valid: true,
      showErr: false,
      errTexts: [],
      requiredRules: [
        v => !!v || 'שדה חובה'
      ],
      usernameRules: [
        v => !!v || 'שדה חובה',
        v => /.+@.+\..+/.test(v) || 'יש להקליד דואר אלקטרוני תקין'
      ]
    }
  },
  created () {
    window.Store.commit('Loader', false)
    bus.$on('Open_snackbar', (snackbarError, snackbarText, Act) => {
      (Act) ? this.ErrorArray = false : this.ErrorArray = true
      this.snackbar = true
      this.snackbarError = snackbarError
      this.snackbarText = snackbarText
    })
    window.axios.interceptors.request.use((config) => {
      config.withCredentials = true
      return config
    }, (error) => {
      return Promise.reject(error)
    })

    window.axios.interceptors.response.use((response) => {
      response.withCredentials = true
      return response
    }, (error) => {
      let errorData = error.response.data
      if (Number(errorData.status) === 2) {
        bus.$emit('Open_snackbar', 'error', errorData.err.message[0], '1')
      }
      return Promise.reject(error)
    })
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 600
    },
    SendContactForm () {
      let data = {
        fullName: this.fullName,
        phoneNumber: this.phoneNumber,
        email: this.email
      }
      let vm = this
      if (this.$refs.contactus.validate()) {
        window.Store.commit('Loader', true)
        window.axios.post(window.API.registerForm, data)
          .then(response => {
            window.Store.commit('Loader', false)
            bus.$emit('Open_snackbar', 'success', 'נשלח בהצלחה!', '1')
            vm.$refs.contactus.reset()
          })
          .catch(error => {
            window.Store.commit('Loader', false)
            vm.showErr = true
            let errorMessage = error.response.data
            vm.errTexts = errorMessage.err
            bus.$emit('Open_snackbar', 'error', errorMessage.err)
          })
      } else {
        bus.$emit('Open_snackbar', 'error', 'התגלתה שגיאה, אנא בדוק את הטופס', '1')
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Assistant', sans-serif !important;
}
@media (min-width: 1904px) {
#app .container {
    max-width: 1140px!important;
}
}
@media (min-width: 1264px) {
#app .container {
    max-width: 1140px!important;
}
}
.footerBackground {
// background-image: url('assets/6xRr3Vcw-scaled2.jpg')!important;
background-repeat: no-repeat!important;
background-attachment: fixed!important;
background-position: top center!important;
background-size: cover!important;
}
// .background {
//   background-color: #111D5E!important;
//   background: #111D5E!important;
//   background: -webkit-linear-gradient(to top, #FFAF7B, #D76D77, #111D5E, #111D5E, #111D5E)!important;
//   background: linear-gradient(to top, #FFAF7B, #D76D77, #111D5E, #111D5E, #111D5E)!important;
// }
.background {
  // background-image: url('assets/background.png')!important;
  background-repeat: no-repeat!important;
  background-attachment: fixed!important;
  background-position: top!important;
  background-size: cover!important;
}
#app .highlighted {
background-color: #D76D77;
color: white !important;
}
.footerBackground .v-messages__message {
  color: white!important;
}
#app .DialogTop {
// margin-top:55px !important;
height: calc(100% - 65px)!important;
height: -o-calc(100% - 65px)!important;/* opera */
height: -webkit-calc(100% - 65px)!important; /* google, safari */
height: -moz-calc(100% - 65px)!important; /* firefox */
}
#app .v-application,
 #app .display-1,
 #app .display-2,
 #app .display-3,
 #app .display-4,
 #app .headline,
 #app .title,
 #app .subtitle-1,
 #app .subtitle-2,
 #app .body-1,
 #app .body-2,
 #app .caption,
 #app .overline,
 #app .font-regular,
 #app .font-weight-light,
 #app .font-weight-thin,
 #app .font-weight-regular,
 #app .font-weight-medium,
 #app .font-weight-bold,
 #app .font-weight-black,
 #app .text-h1,
 #app .text-h2,
 #app .text-h3,
 #app .text-h4,
 #app .text-h5,
 #app .text-h6,
 #app .text-subtitle-1,
 #app .text-subtitle-2,
 #app .text-body-1,
 #app .text-body-2,
 #app .text-caption,
 #app .text-button,
 #app .text-overline,
 #app .font-italic {
   font-family: 'Assistant', sans-serif !important;
}
#app ul.errLogin {
  list-style-type: none!important;
  margin: 0;
  padding: 0;
}
#app button.BtnClasses > span.v-btn__content {
  text-align: right!important;
  display:inline!important;
}
#app .v-stepper, .v-stepper__header {
box-shadow: none !important;
}
#app .v-calendar-daily ::-webkit-scrollbar {
width: 16px!important;
background-color: #E0E0E0;
}
#app ::-webkit-scrollbar, ::-webkit-scrollbar {
width: 10px;
background-color: #E0E0E0;
}
#app ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
background-color: #BDBDBD;
}
.v-slide-group__wrapper {
  touch-action: auto !important;
}
html.loginpage {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body.loginpage {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
/* Handle on hover */
#app ::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:hover {
background: #BDBDBD;
}
#app .Fixheight .v-tabs-bar {
  height: 0px!important;
}
#app .Fixheightdisable .v-tabs-bar {
  height: 48px!important;
}
#app .dialog-right {
  right: 0!important;
  left: auto!important;
}
#app .v-bottom-navigation {
  height: calc(56px + env(safe-area-inset-bottom))!important;
  height: calc(56px + constant(safe-area-inset-bottom))!important;
}
#app .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  height: calc(56px + env(safe-area-inset-top))!important;
  height: calc(56px + constant(safe-area-inset-top))!important;
}
#app .headernew .v-toolbar__content {
  margin-top: env(safe-area-inset-top) !important;
  margin-top: constant(safe-area-inset-top) !important;
}
#app .FixHeader.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  height: calc(121px + env(safe-area-inset-top))!important;
  height: calc(121px + constant(safe-area-inset-top))!important;
}
#app .FixHeaderVod.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  height: calc(79px + env(safe-area-inset-top))!important;
  height: calc(79px + constant(safe-area-inset-top))!important;
}
#app .v-main {
  margin-top: env(safe-area-inset-top) !important;
  margin-top: constant(safe-area-inset-top) !important;
}
#app .v-dialog > .v-sheet.v-card:not(.v-sheet--outlined):not(.NotPadding) {
   padding-top: env(safe-area-inset-top) !important;
   padding-top: constant(safe-area-inset-top) !important;
}
#app .loginClass.theme--light.v-card {
  padding-bottom: env(safe-area-inset-bottom) !important;
  padding-bottom: constant(safe-area-inset-bottom) !important;
}
#app .v-dialog > .v-sheet.v-card:not(.v-sheet--outlined):not(.NotPadding) {
  padding-bottom: env(safe-area-inset-bottom) !important;
  padding-bottom: constant(safe-area-inset-bottom) !important;
}
#app .v-navigation-drawer__content {
   margin-top: env(safe-area-inset-top) !important;
   margin-top: constant(safe-area-inset-top) !important;
}
#app .addActivityCalss {
  padding-bottom: env(safe-area-inset-bottom) !important;
  padding-bottom: constant(safe-area-inset-bottom) !important;
}
#app .editprofileClass {
  padding-bottom: env(safe-area-inset-bottom) !important;
  padding-bottom: constant(safe-area-inset-bottom) !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-top: constant(safe-area-inset-top) !important;
}
#app .dialog-left {
  left: 0!important;
  right: auto!important;
}
.dialog-right-leave-active,
.dialog-right-enter-active {
  transition: 0.5s;
}
.dialog-right-enter {
  transform: translate(100%, 0);
}
.dialog-right-leave-to {
  transform: translate(100%, 0);
}
.dialog-left-leave-active,
.dialog-left-enter-active {
  transition: 0.5s;
}
.dialog-left-enter {
  transform: translate(-100%, 0);
}
.dialog-left-leave-to {
  transform: translate(-100%, 0);
}
#app .fullscreenOur {
  border-radius: 0!important;
  margin: 0!important;
  height: 100%!important;
  max-height: 100%!important;
  position: fixed!important;
  overflow-y: auto!important;
  top: 0!important;
}
// 1976d2
#app .fullheightOur {
  border-radius: 0!important;
  border-left: 1px solid #BDBDBD!important;
  height: 100%!important;
  max-height: 100%!important;
  min-height: 400px;
  background-color: #F8F8F8!important;
}
#app .v-date-picker-table {
height: auto!important;
padding: 0px!important;
}
#app .textContent p {
 display:inline!important;
}
#app .textContent {
 display:inline!important;
}
#app .v-data-table tbody tr.v-data-table__expanded__content {
-webkit-box-shadow: none!important;
box-shadow: none!important;
}
// .v-bottom-navigation {
//   height: auto !important;
// }

// .v-bottom-navigation >>> .v-btn__content {
//   padding-top: 10px;
//   padding-bottom: 10px;
//   margin-bottom: constant( safe-area-inset-bottom ); /* move footer up on iPhone X - iOS 11.0 */
//   margin-bottom: env( safe-area-inset-bottom ); /* move footer up on iPhone X - iOS 11.2 */
// }
#app .user_list div {
  float: right; margin-left: -12px;
  font-family: sans-serif; text-align: center;
  height: 40px; width: 40px; line-height: 40px;
  border-radius: 50%;
  border: 2px solid white; color: white;
}

</style>
